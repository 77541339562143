import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import Img from 'gatsby-image';

export const SubTitle = styled.h2`
  color: #1a5d8f;
  font-size: 4rem;
  margin: 1rem 0;
  ${MEDIA.TABLET`
    text-align: center;
  `};
`;

export const IconsContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${MEDIA.TABLET`
    margin-left: 0;
    margin: auto;
    margin-top: 2rem;
  `};
`;

export const IconContainer = styled.div`
  display: flex;
  flex: 1 0 auto;
  /* justify-content: center; */
  align-items: center;
`;

export const StyledIconImg = styled(Img)`
  width: 1.5rem;
  display: inline-block;
  /* background-color: #fff; */
  padding: 1rem;
`;

export const StyledIconLink = styled.a`
  color: #1a5d8f;
  margin-left: 2rem;
  display: flex;
  align-items: center;
`;

export const AlternateFullRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5vh 10vw;
  height: auto;
  max-height: auto;
  max-width: 100vw;

  background-color: #fff;
  min-height: auto;

  ${MEDIA.TABLET`
    min-height: auto;
    max-height: auto;
  `};
`;

export const StyledLinkTitle = styled.p`
  margin: 0 0 0 2rem;
`;
